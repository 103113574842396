<template>
    <v-container>
        <v-row class="fill-height">
            <v-col>
                <v-sheet height="64">
                    <v-toolbar flat>
                        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                            Σήμερα
                        </v-btn>
                        <v-btn fab text small color="grey darken-2" @click="prev">
                            <v-icon small>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                        <v-btn fab text small color="grey darken-2" @click="next">
                            <v-icon small>
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                            {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-spacer />
                        <v-btn small color="blue" outlined class="mx-2" @click="$router.push({ path: `/expenses/create?calendar=true` })">
                            Καταχώριση Μεροκάματου
                        </v-btn>
                        <v-menu bottom right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                                    <span>{{ typeToLabel[type] }}</span>
                                    <v-icon right>
                                        mdi-menu-down
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="type = 'day'">
                                    <v-list-item-title>Ημέρα</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'week'">
                                    <v-list-item-title>Εβδομάδα</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'month'">
                                    <v-list-item-title>Μήνας</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                    <v-calendar
                        locale="el"
                        ref="calendar"
                        v-model="focus"
                        color="primary"
                        :events="events"
                        :event-color="getEventColor"
                        :type="type"
                        @click:event="showEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        @change="updateRange"
                    />
                    <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
                        <v-card color="grey lighten-4" min-width="350px" flat>
                            <v-toolbar :color="selectedEvent.color" dark>
                                <v-btn icon @click="$router.push({ path: `/expenses/${selectedEvent.expenseId}/edit?calendar=true` })">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-toolbar-title> {{ `${selectedEvent.name} - ${selectedEvent.date}` }} </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <div> {{ `ημερομηνία: ${selectedEvent.date}` }} </div>
                                <div> {{ `εργάτης: ${selectedEvent.name}` }} </div>
                                <div> {{ `οικοδομή: ${selectedEvent.chargeUnit}`}} </div>
                                <div> {{ `μεροκάματο: ${selectedEvent.wage}` }} </div>
                                <div> {{ `έξοδο: ${selectedEvent.amount}` }} </div>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn text color="secondary" @click="selectedOpen = false">
                                    Κλείσιμο
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import expenseService from '@/services/expense.js'
    import momentTimezone from 'moment-timezone'
    export default {
        data: () => ({
            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Μήνας',
                week: 'Εβδομάδα',
                day: 'Ημέρα'
            },
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            events: [],
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            data: []
        }),
        created() {

        },
        methods: {
            getCalendar(min, max) {
                const events = []
                expenseService.getCalendar(min, max).then((response) => {
                    this.data = response.data
                    this.data.forEach(element => {
                        element.dateExpenses.forEach(dateExpense => {
                            dateExpense.expenses.forEach(expense => {
                                events.push({
                                    name: dateExpense.category.title,
                                    chargeUnit: expense.chargeUnit.title,
                                    wage: expense.chargeUnit.wage,
                                    amount: expense.amount,
                                    start: element.date,
                                    end: element.date,
                                    expenseId: expense.expenseId,
                                    date: momentTimezone(element.date).tz('Europe/Athens').format('DD/MM/YYYY'),
                                    color: this.colors[this.rnd(0, this.colors.length - 1)],
                                    timed: 8
                                })
                            })
                        })
                    })
                    this.events = events
                }).catch((error) => {
                    console.log(error)
                })
            },
            viewDay({ date }) {
                this.focus = date
                this.type = 'day'
            },
            getEventColor(event) {
                return event.color
            },
            setToday() {
                this.focus = ''
            },
            prev() {
                this.$refs.calendar.prev()
            },
            next() {
                this.$refs.calendar.next()
            },
            showEvent({ nativeEvent, event }) {
                const open = () => {
                    this.selectedEvent = event
                    this.selectedElement = nativeEvent.target
                    requestAnimationFrame(() => requestAnimationFrame(() => { this.selectedOpen = true }))
                }

                if (this.selectedOpen) {
                    this.selectedOpen = false
                    requestAnimationFrame(() => requestAnimationFrame(() => open()))
                } else {
                    open()
                }

                nativeEvent.stopPropagation()
            },
            updateRange({ start, end }) {
                this.getCalendar(momentTimezone(start.date).tz('Europe/Athens').startOf('day').utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]').toString(), momentTimezone(end.date).tz('Europe/Athens').startOf('day').utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]').toString())
            },
            rnd(a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            }
        }
    }
</script>
