import api from '@/utils/api'

export default {
    getExpenses(page, size, query, sort) {
        let filter = null
        if (Object.values(query).length > 0) {
            filter = Object.values(query).reduce((total, value) => total + `, ${value}`)
        }
        let range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`expenses` + (page ? range : '') + (filter ? `&filter=${encodeURIComponent('{' + filter + '}')}` : '') + (sort ? `&sort=${encodeURIComponent(sort)}` : `&sort=${encodeURIComponent('[id, DESC]')}`))
    },

    getExpensesCsv(query, sort) {
        let filter = null
        if (Object.values(query).length > 0) {
            filter = Object.values(query).reduce((total, value) => total + `, ${value}`)
        }
        return api.get(`expenses/report?` + (filter ? `filter=${encodeURIComponent('{' + filter + '}')}&` : '') + (sort ? `sort=${encodeURIComponent(sort)}` : `sort=${encodeURIComponent('[id, DESC]')}`))
    },

    getExpense(id) {
        return api.get(`expenses/${id}`)
    },

    createExpense(data) {
        return api.post('expenses', data)
    },

    updateExpense(id, data) {
        return api.put(`expenses/${id}`, data)
    },

    deleteExpense(id) {
        return api.delete(`expenses/${id}`)
    },

    checkExisting(title) {
        return api.get(`expenses/check-existing?title=${title}`)
    },

    getChecks(page, size, query, sort) {
        let filter = null
        if (Object.values(query).length > 0) {
            filter = Object.values(query).reduce((total, value) => total + `, ${value}`)
        }
        filter ? filter += ',"paymentMethod":"CHECK"' : filter = '"paymentMethod":"CHECK"'
        let range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`expenses` + (page ? range : '') + (filter ? `&filter=${encodeURIComponent('{' + filter + '}')}` : '') + (sort ? `&sort=${encodeURIComponent(sort)}` : ''))
    },

    getTotals(query) {
        let filter = null
        if (Object.values(query).length > 0) {
            filter = Object.values(query).reduce((total, value) => total + `, ${value}`)
        }
        return api.get(`expenses/sums${filter ? `?filter=${encodeURIComponent('{' + filter + '}')}` : ''}`)
    },

    getCalendar(startDate, endDate) {
        return api.get(`expenses/calendar?startDate=${startDate}&endDate=${endDate}`)
    }
}
